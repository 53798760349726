import React, { useState } from 'react';
import Heading from './Components/Heading';
import CarteVisite from './Components/CarteVisite';
import './App.css';
import TextePresentation from "./Components/TextePresentation";
import TexteRealisation from "./Components/Realisations/TexteRealisation";
import TexteInformation from "./Components/Informations/TexteInformation";
import TexteContact from "./Components/Contacts/TexteContact";
import TexteAcheter from "./Components/Informations/TexteAcheter";
import TexteTransformer from "./Components/Informations/TexteTransformer";
import TexteDecider from "./Components/Informations/TexteDecider";

function App() {
  const [enterIsClicked, setEnterIsClicked] = useState(false);
  const [homeIsClicked, setHomeIsClicked] = useState(true);
  const [presIsClicked, setPresIsClicked] = useState(false);
  const [realIsClicked, setRealIsClicked] = useState(false);
  const [infoIsClicked, setInfoIsClicked] = useState(false);
  const [contIsClicked, setContIsClicked] = useState(false);
  const [acheterIsClicked, setAcheterIsClicked] = useState(false);
  const [transformerIsClicked, setTransformerIsClicked] = useState(false);
  const [deciderIsClicked, setDeciderIsClicked] = useState(false);

  function handleEnterClick() {
    setEnterIsClicked(true);
    setHomeIsClicked(false);
    setPresIsClicked(false);
    setRealIsClicked(false);
    setInfoIsClicked(false);
    setContIsClicked(false);
    setAcheterIsClicked(false);
    setTransformerIsClicked(false);
    setDeciderIsClicked(false);
  }

  function handlePresClick() {
    setHomeIsClicked(false);
    setPresIsClicked(true);
    setEnterIsClicked(false);
    setRealIsClicked(false);
    setInfoIsClicked(false);
    setContIsClicked(false);
    setAcheterIsClicked(false);
    setTransformerIsClicked(false);
    setDeciderIsClicked(false);

  }

  function handleHomeClick() {
    setHomeIsClicked(true);
    setEnterIsClicked(false);
    setPresIsClicked(false);
    setRealIsClicked(false);
    setInfoIsClicked(false);
    setContIsClicked(false);
    setAcheterIsClicked(false);
    setTransformerIsClicked(false);
    setDeciderIsClicked(false);
  }

  function handleRealClick() {
    setRealIsClicked(true);
    setEnterIsClicked(false);
    setContIsClicked(false);
    setInfoIsClicked(false);
    setHomeIsClicked(false);
    setPresIsClicked(false);
    setAcheterIsClicked(false);
    setTransformerIsClicked(false);
    setDeciderIsClicked(false);
  }

  function handleInfoClick() {
    setContIsClicked(false);
    setEnterIsClicked(false);
    setInfoIsClicked(true);
    setRealIsClicked(false);
    setHomeIsClicked(false);
    setPresIsClicked(false);
    setAcheterIsClicked(false);
    setTransformerIsClicked(false);
    setDeciderIsClicked(false);
  }

  function handleContClick() {
    setContIsClicked(true);
    setEnterIsClicked(false);
    setInfoIsClicked(false);
    setRealIsClicked(false);
    setHomeIsClicked(false);
    setPresIsClicked(false);
    setAcheterIsClicked(false);
    setTransformerIsClicked(false);
    setDeciderIsClicked(false);
  }

  function handleAcheterClick() {
    setContIsClicked(false);
    setEnterIsClicked(false);
    setInfoIsClicked(false);
    setRealIsClicked(false);
    setHomeIsClicked(false);
    setPresIsClicked(false);
    setAcheterIsClicked(true);
    setTransformerIsClicked(false);
    setDeciderIsClicked(false);
  }

  function handleTransformerClick() {
    setContIsClicked(false);
    setEnterIsClicked(false);
    setInfoIsClicked(false);
    setRealIsClicked(false);
    setHomeIsClicked(false);
    setPresIsClicked(false);
    setAcheterIsClicked(false);
    setTransformerIsClicked(true);
    setDeciderIsClicked(false);
  }

  function handleDeciderClick() {
    setContIsClicked(false);
    setEnterIsClicked(false);
    setInfoIsClicked(false);
    setRealIsClicked(false);
    setHomeIsClicked(false);
    setPresIsClicked(false);
    setAcheterIsClicked(false);
    setTransformerIsClicked(false);
    setDeciderIsClicked(true);
  }



  return (
    <div >
      {homeIsClicked &&
        <div>
          <CarteVisite
            enter={handleEnterClick}
          />

        </div>
      }

      {enterIsClicked &&
        <div>

          <Heading
            pres={handlePresClick}
            home={handleHomeClick}
            real={handleRealClick}
            cont={handleContClick}
            info={handleInfoClick}
          />
        </div>
      }

      {presIsClicked &&
        <div>
          <TextePresentation
            retour={handleEnterClick}
          />
        </div>
      }
      {realIsClicked &&
        <div>
          <TexteRealisation
            retour={handleEnterClick}
          />
        </div>
      }

      {infoIsClicked &&
        <div>
          <TexteInformation
            retour={handleEnterClick}
            contact={handleContClick}
            acheter={handleAcheterClick}
            transformer={handleTransformerClick}
            decider={handleDeciderClick}
          />
        </div>
      }

      {contIsClicked &&
        <div>
          <TexteContact
            retour={handleEnterClick}
          />
        </div>
      }

      {acheterIsClicked &&
        <div>
          <TexteAcheter
            retour={handleInfoClick}
          />
        </div>
      }

      {transformerIsClicked &&
        <div>
          <TexteTransformer
            retour={handleInfoClick}
          />
        </div>
      }

      {deciderIsClicked &&
        <div>
          <TexteDecider
            retour={handleInfoClick}
          />
        </div>
      }

    </div>
  );
}

export default App;
