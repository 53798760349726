import React from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({

    root: {
        height: "93vh",
        position: "relative",
    },
    texteTitre: {
        
        fontSize: "12vw",
        color: "white",

    },
    texte: {
        position: "relative",
        textAlign: "center",
        fontSize: "3.2vw",
        top: "-1vw",
        color: "white",
    },

    texteEntrer: {
        position: "absolute",
        width: "10vw",
        height: "10vw",
        justifyContent: "center",
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
        
    },

    image: {
        height: "10%",
        width: "10%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
    }


}))

function CarteVisite(props) {
    const classes = useStyles();

    function Entrez () {
        props.enter()
    }

    return (


        <div className="divPrincipal" >
            <div className="rondAcceuil" >
                <div className="cercleBlanc"></div>
                <div className="ensembleTexte " >
                    <Typography  className={classes.texteTitre} variant="h1">PMH</Typography>
                    <Typography  className={classes.texte} variant="h3" >Pimp My House</Typography>

                </div>
                <div className="ligneBlancheSup"></div>
                <div className="ligneBlancheInf"></div>
            </div>
            <div className="rondEnter">
            <Button variant="text" className={classes.texteEntrer} onClick={Entrez} >Entrer</Button>
            </div>
        </div>


    )
}

export default CarteVisite;