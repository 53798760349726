import React from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({

    texte: {
        textAlign: "center",
        color: "white",
        lineHeight: "1.6",
        fontSize: "1.5vw",

    },
    texteRetour: {
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
        textAlign: "center",
        lineHeight: "1.3",
    },
    
}))



function TexteTransformer(props) {
    const classes = useStyles();

    function Retour() {
        props.retour()
    }



    return (
        <div >

            <div className="rondPrix">
                <Typography className={classes.texte} >Vous voulez changer mais n’arrivez pas à concrétiser vos envies</Typography>
            </div>
            <div className="rondConnecter">
                <Typography className={classes.texte} >1<br />Je viens chez vous et vous me parlez de vos projets et de vos gouts.<br />
Nous déterminons ensemble votre style et la palette de couleurs et matières qui y correspondent.</Typography>
            </div>

            <div className="rondAcheter">
            <Typography className={classes.texteRetour} ><u>Pour vous aider à<br />choisir et visualiser les transformations<br />envisagées.</u></Typography>
            </div>

            <div className="rondDecider">
            <Typography className={classes.texte} >2<br />Je numérise vos plans<br />actuels (papier ou PDF).<br />
Vous n’en possédez pas ou ils ne sont pas complets, je m’en occupe.</Typography> 
            </div>

            <div className="rondTransformer">
            <Typography className={classes.texte} >3<br />Je réalise les modifications en 3D et les soumets à votre approbation. J’établis la liste d’achat vous permettant de procéder aux transformations envisagées.</Typography>
            </div>

            <div className="rondEnter">
                <Button variant="text" className={classes.texteRetour} onClick={Retour} >Retour</Button>
            </div>
        </div>


    )
}

export default TexteTransformer;
