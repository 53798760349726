import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
        
        width: "100%",
        position: "fixed",
        bottom: "0",
        height: "55px",
            },
     bar: {
        
     },       
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
            },
    buttonApp: {
                marginRight: theme.spacing(6),
            },
    rondTexte: {
        width: "17vw",
        height: "17vw",
        textAlign: "center",
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },
    rondTexteHome: {
        width: "10vw",
        height: "10vw",
        textAlign: "center",
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },
    rondTexteReal: {
        width: "15vw",
        height: "15vw",
        textAlign: "center",
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },

    rondTexteInfo: {
        width: "16vw",
        height: "16vw",
        textAlign: "center",
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },
    rondTexteCont: {
        width: "13vw",
        height: "13vw",
        textAlign: "center",
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },
}));

function Heading(props) {
    
    const classes = useStyles();

    function homeIsClicked (){
        props.home()
    }

    function presIsClicked (){
        props.pres()
    }

    function realIsClicked (){
        props.real()
    }

    function infoIsClicked (){
        props.info()
    }

    function contIsClicked (){
        props.cont()
    }



    return (
        <div>
            <div className="rondPres">
            <Button onClick={presIsClicked} className={classes.rondTexte}><u>Présentation</u></Button>
            </div>

             <div className="rondHome">
             <Button onClick={homeIsClicked} className={classes.rondTexteHome} ><u>Home</u></Button>
            </div>   
                    
            <div className="rondReal">
             <Button onClick={realIsClicked} className={classes.rondTexteReal} ><u>Réalisations</u></Button>
            </div>         
                    
            <div className="rondInfo">
             <Button onClick={infoIsClicked} className={classes.rondTexteInfo} ><u>Informations</u></Button>
            </div>         

            <div className="rondCont">
             <Button onClick={contIsClicked} className={classes.rondTexteCont} ><u>Contact</u></Button>
            </div>    
                    
                
            
        
        
        </div>
    )
}

export default Heading;