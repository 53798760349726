import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider , createMuiTheme } from '@material-ui/core/styles';
import './index.css';
import App from './App';

const theme= createMuiTheme ({
  typography: {
    fontFamily: "Mostra Nuova W05 Regular",
  },
  
  palette: {
    primary: {
      main: "#090E29"
    },
    secondary: {
      main: "#BA9400"
    }
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
  , document.getElementById('root')
);

