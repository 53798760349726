import React from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import portrait from "./portrait.jpeg";
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {

    },
    texte: {
        position: "absolute",
        top: "7.5vw",
        margin: "auto",
        textAlign: "center",
        lineHeight: "1.6",
        paddingBottom: "50px",
        color: "white",
        fontSize: "1.5vw",
    },
    carte: {
        padding: "5px 0",
    },
    image: {
        height: "5vw",
        width: "5vw",
        borderRadius: "50%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "0.5vw",
    },
    texteRetour: {
        position: "absolute",
        width: "10vw",
        height: "10vw",
        justifyContent: "center",
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },
}))

const presentation = (<div>
    Parallèlement à mon activité d’indépendante que <br/>j’exerce maintenant depuis plus de 20 ans,
    j’ai toujours été très <br/>attirée par la création d’ambiance et d’aménagement.
    Après plusieurs <br/>conseils officieux auprès de mes connaissances et quelques réalisations<br/> amicales ou personnelles,
    j’ai décidé de passer le cap de façon plus officielle<br/> et de vous proposer mes conseils dans ce domaine.<br />
    C’est donc avec beaucoup d’enthousiasme que je vous invite à me contacter
    si<br/> vous souhaitez un petit coup de pouce pour booster votre décoration!
    Je vous propose d’entamer un dialogue qui nous permettra de concrétiser vos envies
    pour que votre intérieur reflète votre personnalité et/ou celle de votre habitation.<br />
    A travers de petits remaniements (couleurs, matières, agencement, tissus, …),<br/>
    vous vous rendrez compte que l’ambiance générale de votre pièce peut<br/> tout à fait se révéler.<br />
    Si vous souhaitez tenter l’aventure, embarquons ensemble.<br />
</div>);

function TextePresentation(props) {
    const classes = useStyles();

    

    function Retour () {
        props.retour()
    }
    return (
        <div className="divPrincipal" >
            <div className="rondAcceuil" >
                <div className="cercleBlanc" >
                    <img src={portrait} className={classes.image} alt="" />
                    <Typography className={classes.texte} variant="h6">{presentation}</Typography>
                </div>
                <div className="ligneBlancheSup"></div>
                <div className="ligneBlancheInf"></div>
            </div>
            <div className="rondEnter">
            <Button variant="text" className={classes.texteRetour} onClick={Retour} >Retour</Button>
            </div>
        </div>

    )
}

export default TextePresentation;