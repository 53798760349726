import React from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({

    texte: {
        textAlign: "center",
        color: "white",
        lineHeight: "1.6",
        fontSize: "1.5vw",

    },
    texteRetour: {
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },

}))

function TexteAcheter(props) {
    const classes = useStyles();

    function Retour() {
        props.retour()
    }

    return (
        <div >
            <div className="rondConnecter">
                <Typography className={classes.texte} >1<br />Vous aimez la localisation du bien, le budget vous convient mais vous restez dubitatif sur son potentiel.</Typography>
            </div>

            <div className="rondAcheter">
                <Typography className={classes.texteRetour} ><u>Pour acheter</u></Typography>
            </div>

            <div className="rondDecider">
                <Typography className={classes.texte} >3<br />Je vous remets quelques plans et photos pour que vous puissiez vous rendre compte s'il peut ou non contenter vos attentes.</Typography>
            </div>

            <div className="rondTransformer">
                <Typography className={classes.texte} >2<br />Nous faisons ensemble une contre visite pendant laquelle je prends des notes et des photos.</Typography>
            </div>

            <div className="rondEnter">
                <Button variant="text" className={classes.texteRetour} onClick={Retour} >Retour</Button>
            </div>
        </div>


    )
}

export default TexteAcheter;
