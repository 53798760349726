import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import salon3D from "./Images/WSPSalonPlansAvant.JPG";
import salonPlan from "./Images/WSPSalonPlansApres.jpeg";
import VHcuisinePlan from "./Images/VHPlans4.JPG";
import VHcuisine from "./Images/Cuisine3Apres.JPG";
import VHcuisine2Plan from "./Images/VHPlans6.JPG";
import VHcuisine2 from "./Images/Cuisine2Apres.JPG";
import WSLSalon from "./Images/WSLSalon.jpeg";
import WSLSalonPlan from "./Images/WSLSalonPlan.PNG";
import WSLSdb from "./Images/WSLSdb.jpeg";
import WSLSdbPlan from "./Images/WSLSdbPlan.PNG";



const useStyles = makeStyles((theme) => ({
    gridList: {
        height: "auto",
        backgroundColor: "#0C344A",
    },
    dialog: {
        color: "white",
        backgroundColor: "#0C344A",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        color: "white",
    },
    tileBar: {
        backgroundColor: "transparent"
    }

}))



function Plans(props) {
    const classes = useStyles();

    function handleClose() {
        props.close();
    }

    return (
        <Dialog open={props.open} onClose={handleClose} fullScreen >
            <DialogTitle className={classes.dialog} >
                Plans 3D
                <IconButton color="secondary" className={classes.closeButton} onClick={handleClose} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <GridList cellHeight={500} spacing={8} className={classes.gridList} cols={4} >
                
                <GridListTile cols={2} >
                    <img src={salon3D} alt="salon" />
                </GridListTile>
                <GridListTile cols={2} >
                    <img src={salonPlan} alt="salon" />
                </GridListTile>

                <GridListTile cols={2} >
                    <img src={VHcuisinePlan} alt="" />
                </GridListTile>
                <GridListTile cols={2} >
                    <img src={VHcuisine} alt="" />
                </GridListTile>

                <GridListTile cols={2} >
                    <img src={VHcuisine2Plan} alt="" />
                </GridListTile>
                <GridListTile cols={2} >
                    <img src={VHcuisine2} alt="" />
                </GridListTile>

                <GridListTile cols={2} >
                    <img src={WSLSalonPlan} alt="" />
                </GridListTile>
                <GridListTile cols={2} >
                    <img src={WSLSalon} alt="" />
                </GridListTile>

                <GridListTile cols={2} >
                    <img src={WSLSdbPlan} alt="" />
                </GridListTile>
                <GridListTile cols={2} >
                    <img src={WSLSdb} alt="" />
                </GridListTile>
            </GridList>
        </Dialog>
    )
}

export default Plans;