import React from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    texte: {
        lineHeight: "2",
        color: "white",
        fontSize: "2.6vw",

    },
    carte: {
        

        display: "flex",
        height:"48vw",
        width: "48vw",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        
    },
    
    texteRetour: {
        position: "absolute",
        width: "10vw",
        height: "10vw",
        justifyContent: "center",
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },
}))



function TexteContact(props) {
    const classes = useStyles();
    function Retour() {
        props.retour()
    }
    return (
        <div className="divPrincipal" >
            <div className="rondAcceuil" >
                <div className="cercleBlanc" >
                    <div className={classes.carte} >
                        <Typography color="secondary" className={classes.texte} variant="h4">Céline Taïta</Typography>
                        <Typography color="secondary" className={classes.texte} variant="h4">0493 94 32 55</Typography>
                        <Typography color="secondary" className={classes.texte} variant="h4">pimpmyhousebxl@gmail.com</Typography>

                    </div>
                </div>
                <div className="ligneBlancheSup"></div>
                <div className="ligneBlancheInf"></div>
            </div>
            <div className="rondEnter">
                <Button variant="text" className={classes.texteRetour} onClick={Retour} >Retour</Button>
            </div>
        </div>
    )
}

export default TexteContact;