import React from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({

    texte: {
        textAlign: "center",
        color: "white",
        lineHeight: "1.6",
        fontSize: "1.5vw",
        cursor: "pointer",
    },

    carte: {
        padding: "5px 0",
    },
    texteRetour: {
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },
    bouttonConnecter: {
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
        lineHeight: "1.3",
    }
}))





function TexteInformation(props) {
    const classes = useStyles();

    function Retour() {
        props.retour()
    }

    function contactIsClicked() {
        props.contact()
    }

    function acheterIsClicked() {
        props.acheter()
    }

    function transformerIsClicked() {
        props.transformer()
    }

    function deciderIsClicked() {
        props.decider()
    }
    return (
        <div >
            <div className="rondConnecter">

                <Button onClick={contactIsClicked} className={classes.bouttonConnecter} ><u>Se contacter</u></Button>

                <Typography onClick={contactIsClicked} className={classes.texte} >-Toujours par email ou téléphone<br />
                                                        -Bien entendu gratuit<br />
                                                         -Projets sur BXL et environs<br />
                                                         - Si gros oeuvre envisagé, l'aval d'un<br />professionnel nécessaire
                </Typography>
            </div>

            <div className="rondAcheter">
                <Button onClick={acheterIsClicked} className={classes.bouttonConnecter} ><u>Pour acheter</u></Button>
            </div>

            <div className="rondDecider">
                <Button onClick={deciderIsClicked} className={classes.bouttonConnecter} ><u>Pour vous aider<br />à choisir</u></Button>
            </div>

            <div className="rondTransformer">
                <Button onClick={transformerIsClicked} className={classes.bouttonConnecter} ><u>Pour vous aider à<br />choisir et visualiser les transformations<br />envisagées</u></Button>
            </div>

            <div className="rondEnter">
                <Button variant="text" className={classes.texteRetour} onClick={Retour} >Retour</Button>
            </div>
        </div>


    )
}

export default TexteInformation;