import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import samAvant from "./Images/samAvant.jpeg";
import samApres from "./Images/samApres.jpeg";
import doucheAvant from "./Images/doucheAvant.jpeg";
import doucheApres from "./Images/doucheApres.jpg";
import salonAvant from "./Images/salonAvant.jpeg";
import salonApres from "./Images/salonApres.jpeg";
import hallAvant from "./Images/hallAvant.jpeg";
import hallApres from "./Images/hallApres.jpeg";
import VerAvant from "./Images/VerCuisineAvant.jpeg";
import VerApres from "./Images/VerCuisineApres.JPG";


const useStyles = makeStyles((theme) => ({
    gridList: {
       // maxWidth: "100%",
        height: "auto",
        backgroundColor: "#0C344A",
    },
    dialog: {
        color:"white",
        backgroundColor: "#0C344A",  
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        color: "white",
    },
    tileBar: {
        backgroundColor: "transparent"
    }

}))



function Maison(props) {
    const classes = useStyles();

    function handleClose() {
        props.close();
    }

    return (
        <Dialog open={props.open} onClose={handleClose} fullScreen >
            <DialogTitle className={classes.dialog} >
                Photos
                <IconButton color="secondary" className={classes.closeButton} onClick={handleClose} >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
            <GridList cellHeight={500} spacing={8} className={classes.gridList} cols={4} >
                <GridListTile  cols={2} >
                    <img  src={salonAvant} alt="salonAvant"/>
                </GridListTile>
                <GridListTile cols={2} >
                    <img src={salonApres} alt="" />
                </GridListTile>
                <GridListTile cols={2} >
                    <img src={samAvant} alt="" />
                </GridListTile>
                <GridListTile cols={2} >
                    <img src={samApres} alt="" />
                </GridListTile>
                <GridListTile cols={0.6} rows={1.4} >
                    
                </GridListTile>
                <GridListTile cols={1.4} rows={1.4} >
                    <img src={hallAvant} alt="" />
                </GridListTile>
                <GridListTile cols={1.4} rows={1.4} >
                    <img src={hallApres} alt="" />
                </GridListTile>
                <GridListTile cols={0.6} rows={1.4} >
                    
                </GridListTile>
                <GridListTile cols={0.6} rows={1.4} >
                    
                </GridListTile>
                <GridListTile cols={1.4} rows={1.4} >
                    <img src={doucheAvant} alt="" />
                </GridListTile>
                <GridListTile cols={1.4} rows={1.4} >
                    <img src={doucheApres} alt="" />
                </GridListTile>
                <GridListTile cols={0.6} rows={1.4} ></GridListTile>
                
                <GridListTile  cols={2} >
                    <img  src={VerAvant} alt="" />
                </GridListTile>
                <GridListTile cols={2} >
                    <img src={VerApres} alt="" />
                </GridListTile>

            </GridList>
        </Dialog>
    )
}

export default Maison;