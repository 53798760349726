import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Maison from "./Maison";
import Plans from "./Plans";
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "55px",
    },
    texte: {
        textAlign: "center",
        color: "#090E29"
    },
    carte: {
        margin: "30px",
    },
    cardContent: {
        backgroundColor: "#BA9400"
    },
    texteRetour: {
        position: "absolute",
        width: "10vw",
        height: "10vw",
        justifyContent: "center",
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
    },
    bouttonConnecter: {
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
        lineHeight: "1.3",
    }
}))


function TexteRealisation(props) {
    const [isClicked, setIsClicked] = useState(false);
    const [isClickedPlan, setIsClickedPlan] = useState(false);



    const classes = useStyles();

    function Retour() {
        props.retour()
    }

    function handleClick() {
        setIsClicked(!isClicked);
    }

    function handleClickPlan() {
        setIsClickedPlan(!isClickedPlan);
    }

    return (
        <div>
            <div className="rondPhoto">
                <div className="cerclePhoto" >
                <div className="ligneBlancheSupPhoto"></div>
                <Button onClick={handleClick} className={classes.bouttonConnecter} >Photos</Button>
                <div className="ligneBlancheInfPhoto"></div>
                </div>
            </div>

            <div className="rondPlan">
                <div className="cerclePlan" >
                <div className="ligneBlancheSupPlan"></div>
                <Button onClick={handleClickPlan} className={classes.bouttonConnecter} >Plans 3D</Button>
                <div className="ligneBlancheInfPlan"></div>
                </div>
            </div>


            <Maison
                open={isClicked}
                close={() => setIsClicked(false)}
            />
            <Plans
                open={isClickedPlan}
                close={() => setIsClickedPlan(false)}
            />
            <div className="rondEnter">
                <Button variant="text" className={classes.texteRetour} onClick={Retour} >Retour</Button>
            </div>


        </div>


    )
}

export default TexteRealisation;