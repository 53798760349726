import React from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({

    texte: {
        textAlign: "center",
        color: "white",
        lineHeight: "1.6",
        fontSize: "1.5vw",

    },
    texteRetour: {
        color: "white",
        fontSize: "2.5vw",
        textTransform: "none",
        textAlign: "center",
    },
    
}))



function TexteDecider(props) {
    const classes = useStyles();

    function Retour() {
        props.retour()
    }



    return (
        <div >

            <div className="rondPrix">
                <Typography className={classes.texte} >Vous avez déjà une<br />idée des changements que vous envisagez et souhaitez conforter vos choix.</Typography>
            </div>
            <div className="rondConnecter">
                <Typography className={classes.texte} >1<br />Je viens chez vous et vous me parlez de vos projets et de vos gouts.</Typography>
            </div>

            <div className="rondAcheter">
            <Typography className={classes.texteRetour} ><u>Pour vous aider à choisir</u></Typography>
            </div>

            <div className="rondTransformer">
            <Typography className={classes.texte} >2<br />Nous déterminons ensemble votre style et la palette de couleurs et matières qui y correspondent.</Typography>
            </div>

            <div className="rondEnter">
                <Button variant="text" className={classes.texteRetour} onClick={Retour} >Retour</Button>
            </div>
        </div>


    )
}

export default TexteDecider;
